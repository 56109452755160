<template>
    <div class="records">
        <div v-for="log,i in records" :key="i">
          <div v-if="log.block_hash!=''" class="item">
          <div class="icon">
            <img :src="'assets/game_icon_'+(log.game_id)+'/1x.png'" alt="">
          </div>
          <div class="content">
            <div class="flex-justify title">
              <span class="game-title">{{$t('common.gameNames')[log.game_id]}}</span>
              <span>{{log.human_time}}</span> 
            </div>
            <mu-row gutter>
              <mu-col span="5">
                <div class="label">{{$t('common.blockNumber')}}</div>
              </mu-col>
              <mu-col span="7">
                <div>[{{log.block_number}}]</div>
              </mu-col>
            </mu-row>
            <mu-row gutter>
              <mu-col span="5">
                <div class="label">{{$t('common.blockHash')}}</div>
              </mu-col>
              <mu-col span="7">
                <div>[{{log.block_hash}}]</div>
              </mu-col>
            </mu-row>
            <mu-row gutter>
              <mu-col span="5">
                <div class="label">{{$t('common.amount')}}</div>
              </mu-col>
              <mu-col span="7">
                <div class="flex-amount-win">
                  <div v-if="log.game_id==2">
                    <div v-for="a,i in log.value.split(',')" :key="'am_v'+i">
                      <span v-if="a>0">{{$t('common.gameRoadNames')[2][i]}} {{a/1000000}}USDT</span>
                    </div>
                  </div>
                  <div v-else>
                    <span v-if="log.game_id==1">
                      {{$t('common.gameRoadNames')[1][log.direction]}}[{{log.value.split(',')[0]/1000000}}USDT]
                    </span>
                    <span v-else>
                      [{{log.value.split(',')[0]/1000000}}USDT]
                    </span>
                  </div>
                  
                  <span v-if="log.win" class="win">{{$t('common.rWin')}}</span>
                </div>
              </mu-col>
            </mu-row>
            <mu-row gutter v-if="log.win">
              <mu-col span="5">
                <div class="label">{{$t('common.rGot')}}</div>
              </mu-col>
              <mu-col span="7">
                <div class="green">[{{log.reward/1000000}}USDT]</div>
              </mu-col>
            </mu-row>
            <mu-row gutter>
              <mu-col span="5">
                <div class="label">{{$t('common.rView')}}</div>
              </mu-col>
              <mu-col span="7">
                <div>[<span class="a-link" @click="viewTxOnTronscan(log.transaction_id)">tronscan.org</span>]</div>
              </mu-col>
            </mu-row>
          </div>
          </div>
        </div>
      <div class="center">
        <div v-if="noMore">{{$t('common.noMoreData')}}</div>
        <div v-else @click="loadMore">{{$t('common.loadMore')}}</div>
      </div>
    </div>
</template>
<script>

export default {
  name: 'Records',
  data(){
    return {
      reverse: true,
      records: [],
      noMore: false,
      page: 1,
      size: 10
    }
  },
  components:{
  },
  methods:{
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    viewTxOnTronscan(txID){
      window.open("https://tronscan.org/#/transaction/"+txID);
    },
    refresh(){
      console.log('refresh')
      this.$router.go(0);
    },
    loadMore(){
      console.log('loadmore')
      this.page += 1;
      this.getRecords();
    },
    getRecords(){
      let address = window.walletAddress;
      let gid = this.$store.state.currentGameId + 1;
      let _this = this;
      console.log(address);
      this.$http.call(this.$http.api.records,{
          params: {
            address: address,gid:gid, page:_this.page, size:_this.size,sort:'desc'
          }
      }).then(function(res){
        console.log(res)
        if(res.data.length>0){
          _this.records = _this.records.concat(res.data);
        }else{
          _this.noMore = true;
        }
      },function(res){
        console.log('something goes wrong...');
        console.log(res);
      });
    }
  },
  mounted(){
    this.$store.commit("setClosablePage",true);

    let itvl = setInterval(async ()=>{
      // console.log(this.$store.state.walletAddress)
      if(window.walletAddress){
          clearInterval(itvl)
          this.getRecords()
      }
    }, 10);
  }
}
</script>
<style lang="less" scoped>
.records{
  font-size: 24px;
  font-weight: lighter;
  padding: 0 28px;
  word-wrap: break-word;
  word-break: break-all;
  .item{
    border-radius: 16px;
    background: @bg-white;
    padding: 1.5*@base-pd @base-pd;
    color: @bg-color;
    margin: @base-pd 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .icon{
      padding: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      // border: 1px solid @bg-color;
      // border-radius: 4px;
      margin-right: @base-pd;
      img{
        width: 50px;
        // border: 1px solid @bg-color;
        // border-radius: 50%;
      }
    }
    .content{
      text-align: left;
      flex: 1;
      .title{
        padding-bottom: @base-pd;
        .game-title{
          font-size: 28px;
          font-weight: 700;
        }
      }
      .row{
        margin: 5px -8px;
      }
      .flex-amount-win{
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .win{
        background: @green;
        border-radius: 6px;
        color: @bg-white;
        padding: 2px 4px;
      }
      .green{
        color: @green;
      }
      // .flex-justify{
      //   align-items: flex-start;
      //   justify-content: space-between;
        
      // }
      
    }
    
  }
}

// .title{
//   font-size: 24px;
//   font-weight: bold;
//   margin-bottom: 2rem;
//   text-align: center;
//   position:relative;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// }
// .title .goback{
//   font-size: .6em;
//   color: #fff;
// }
// .title .refresh{
//   font-size: .6em;
//   cursor: pointer;
// } 
// .timeline{
//   color: #fff;
// }

// .timeline p{
//   padding: 3px 0;
// }
// .timeline .gray{
//   color: #a7a7a7;
// }
// .ant-timeline-item{
//   max-width: 870px;
// }
</style>
